/**
 * User - Service
 * Der user-service aggregiert Benutzertyp übergreifend Benutzer (Innendienst, Außendienst, Mitbenutzer, Assistenten etc.) und gibt deren \"Basisstammdaten\" zurück. Der Service kann im Kontext eines angemeldeten Benutzers ausgeführt werden, so wie als technischer Service aufgerufen werden. Im Kontext eines angemeldeten Benutzers liest der Service nur die Benutzerdaten des angemeldeten Benutzers.
 *
 * The version of the OpenAPI document: v2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MatomoUser { 
    /**
     * Im Kontext von Matomo kein INNENDIENST
     */
    userTyp?: MatomoUser.UserTypEnum;
    /**
     * Praxisstufe
     */
    praxisStufe?: string;
    /**
     * Strukturstufe
     */
    strukturstufe?: string;
    /**
     * Altersgruppe: unter 30 Jahren (< 30 Jahre), 30-40 Jahre (>= 30 Jahre & < 40 Jahre), 40-50 Jahre (>= 40 Jahre & < 50 Jahre), über 50 Jahre (>= 50 Jahre)
     */
    ageGroup?: MatomoUser.AgeGroupEnum;
    /**
     * Betriebszugehörigkeit: unter einem Jahr (< 1 Jahr), 1-3 Jahre (>= 1 Jahr & < 3 Jahre), 3-10 Jahre (>= 3 Jahr & < 10 Jahre), über 10 Jahre (>= 10 Jahre)
     */
    periodOfEmployment?: MatomoUser.PeriodOfEmploymentEnum;
    /**
     * Clubzugehörigkeit
     */
    clubMembership?: Array<string>;
    /**
     * Direktionsnummer
     */
    direktionsnummer?: number;
}
export namespace MatomoUser {
    export type UserTypEnum = 'AUSSENDIENST' | 'INNENDIENST' | 'MITBENUTZER' | 'HOTLINE' | 'AUTOTEST' | 'KONZERN_MITARBEITER' | 'SCHUELER' | 'ASSISTENT' | 'SERVICE_ACCOUNT';
    export const UserTypEnum = {
        Aussendienst: 'AUSSENDIENST' as UserTypEnum,
        Innendienst: 'INNENDIENST' as UserTypEnum,
        Mitbenutzer: 'MITBENUTZER' as UserTypEnum,
        Hotline: 'HOTLINE' as UserTypEnum,
        Autotest: 'AUTOTEST' as UserTypEnum,
        KonzernMitarbeiter: 'KONZERN_MITARBEITER' as UserTypEnum,
        Schueler: 'SCHUELER' as UserTypEnum,
        Assistent: 'ASSISTENT' as UserTypEnum,
        ServiceAccount: 'SERVICE_ACCOUNT' as UserTypEnum
    };
    export type AgeGroupEnum = 'upto30' | 'from30upto40' | 'from40upto50' | 'from50';
    export const AgeGroupEnum = {
        Upto30: 'upto30' as AgeGroupEnum,
        From30upto40: 'from30upto40' as AgeGroupEnum,
        From40upto50: 'from40upto50' as AgeGroupEnum,
        From50: 'from50' as AgeGroupEnum
    };
    export type PeriodOfEmploymentEnum = 'upto1Year' | 'from1upto3Years' | 'from3upto10Years' | 'from10Years';
    export const PeriodOfEmploymentEnum = {
        Upto1Year: 'upto1Year' as PeriodOfEmploymentEnum,
        From1upto3Years: 'from1upto3Years' as PeriodOfEmploymentEnum,
        From3upto10Years: 'from3upto10Years' as PeriodOfEmploymentEnum,
        From10Years: 'from10Years' as PeriodOfEmploymentEnum
    };
}


